import Vue from 'vue'
import { abandonApplication } from '@/services/api'
import { ApiErrorHandler } from '@/utils/exception-handler'
import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
import assert from 'assert'
import { inspect, logger } from '@/utils/logger'
import { assertCtxNotType } from '@/utils/assert-helper'
import { AxiosError } from 'axios'
import { goToInitialPageWithCleanState, goToPageWithCleanState } from '@/utils/routerUtils'
import { marketingPagePaths } from '@/routes/marketingRoutes'
import { NavigationGuardNext, Route } from 'vue-router'
import { setApplicationAndApplicant } from '@/services/auth'

export default Vue.extend({
    data() {
        const priorApplicationResponseData = appSessionStorage.getItem(sessionStorageKey.priorApplicationFoundResponseJSON)
        assert(priorApplicationResponseData, 'No prior application data found in session storage')
        const priorApplicationResponse = JSON.parse(priorApplicationResponseData)

        return {
            returnToken2: priorApplicationResponse.returnToken2,
            priorStatus: priorApplicationResponse.priorStatus,
            priorLoanApplicationId: priorApplicationResponse.priorLoanApplicationId,
            needsDOBValidation: !!priorApplicationResponse.needsDOBValidation,
            isPriorityApplication: !!priorApplicationResponse.isPriorityApplication,
            errorText: '',
            loading: true,
        }
    },
    /**
     * https://router.vuejs.org/guide/advanced/navigation-guards.html#in-component-guards
     */
    beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext) {
        // This mixin only works if we have priorApplicationFoundResponseJSON stored locally. If an applicant
        // accidentally navigates to this page directly, we'll safely navigate them to the landing page.
        const priorApplicationResponseData = appSessionStorage.getItem(sessionStorageKey.priorApplicationFoundResponseJSON)
        if (!priorApplicationResponseData) {
            logger.warn(
                `Trying to create prior application mixin data, but no prior application data found in session storage. ` +
                    `This can happen if an applicant navigates to /shared/select_application directly, rather than being redirected there. ` +
                    `We'll go ahead and route the applicant to the landing page instead.`
            )
            goToInitialPageWithCleanState()
        } else {
            next()
        }
    },
    methods: {
        onContinuePrior: async function () {
            logger.info('Continuing prior application')
            try {
                if (this.loading) return
                this.loading = true
                this.$logEvent('click_button_continue_prior_application')

                assertCtxNotType(this, 'isCoApplicant', 'undefined')
                // @ts-ignore assert takes care of this
                await abandonApplication({ wantsToAbandonCurrentApplication: true, returnToken2ForPriorApplication: this.returnToken2, isCoApplicant: this.isCoApplicant })

                const priorApplicationResponseData = appSessionStorage.getItem(sessionStorageKey.priorApplicationFoundResponseJSON)
                const priorApplicationResponse = JSON.parse(priorApplicationResponseData)
                await setApplicationAndApplicant(priorApplicationResponse.priorLoanApplicationId, priorApplicationResponse.priorApplicantId)
                logger.log(`Continuing to prior application using returnToken2: ${this.returnToken2}`)
                // Use replace to disallow navigating back to this screen
                const jodl = `/a/${this.returnToken2}`
                window.history.replaceState(null, '', jodl)
                return goToPageWithCleanState(jodl)
            } catch (e) {
                logger.warn(`An error occurred when trying to continue prior application using returnToken2: ${this.returnToken2}. ${inspect(e as AxiosError)}`)
                this.errorText = ApiErrorHandler(e)
                this.loading = false
            }
        },
        onContinueCurrent: async function () {
            logger.info('Continuing current new application')
            try {
                if (this.loading) return
                this.loading = true
                this.$logEvent('click_button_continue_current_application')

                assertCtxNotType(this, 'isCoApplicant', 'undefined')
                // @ts-ignore assert takes care of this
                await abandonApplication({ wantsToAbandonCurrentApplication: false, returnToken2ForPriorApplication: this.returnToken2, isCoApplicant: this.isCoApplicant })
                await setApplicationAndApplicant(appSessionStorage.getItem(sessionStorageKey.loanApplicationId), appSessionStorage.getItem(sessionStorageKey.applicantId))
                assertCtxNotType(this, 'pathAfterNextOne', 'undefined')
                // @ts-ignore assert takes care of this
                logger.log(`Continuing current new application to path: ${this.pathAfterNextOne}`)
                // @ts-ignore assert takes care of this
                await this.$router.replace(this.pathAfterNextOne)
            } catch (e) {
                logger.warn(`An error occurred when trying to continue current application. ${inspect(e as Object)}`)
                this.errorText = ApiErrorHandler(e as AxiosError)
                this.loading = false
            }
        },
    },
})
