<template>
    <div class="layout pt-0">
        <onboarding-layout
            :error-text="errorText"
            :loading="loading"
        >
            <h5 class="fw-light mb-5">
                <strong>Are You Sure?</strong><br>You have an {{ this.offerText }} on the previous application. If you continue, the old offer will be canceled.
            </h5>
            <div class="d-grid">
                <button
                    class="btn btn-primary mb-2"
                    @click="onGoBack"
                >
                    No, Continue With Previous Offer
                </button>
            </div>
            <div class="d-grid">
                <button
                    class="btn btn-secondary mb-2"
                    @click="onContinueCurrent"
                >
                    Yes, Cancel Previous Offer
                </button>
            </div>
            <p class="text-muted mt-0">
                By continuing with your new application, the previous one will be closed.
            </p>
        </onboarding-layout>
    </div>
</template>

<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import { LoanApplicationStatus } from '@/utils/constants'
    import priorApplicationMixin from '../../mixins/priorApplicationMixin'
    import { logger } from '../../utils/logger'

    // Keys of this object must be members of aven_backend/src/entity/loanApplication.ts::LoanApplicationStatus
    const PREFERRED_PRIOR_STATUS_TEXT_DICT = {
        [LoanApplicationStatus.offered]: 'offer',
        [LoanApplicationStatus.accepted]: 'accepted offer',
        [LoanApplicationStatus.awaitingSuccessfulNotarizations]: 'accepted offer',
    }
    // Default should never be shown to the user but better be safe than sorry
    const DEFAULT_TEXT = 'application'
    const PREFERRED_PRIOR_STATUS = Object.keys(PREFERRED_PRIOR_STATUS_TEXT_DICT)

    export default {
        components: {
            OnboardingLayout,
        },
        mixins: [priorApplicationMixin],
        data() {
            return {
                pathAfterNextOne: this.$route.query.a,
                previousPath: this.$route.query.b,
                isCoApplicant: this.$route.query.c,
                loading: true,
            }
        },
        computed: {
            offerText() {
                if (this.isPreferredStatus) {
                    return PREFERRED_PRIOR_STATUS_TEXT_DICT[this.priorStatus]
                }
                return DEFAULT_TEXT
            },
            isPreferredStatus() {
                return PREFERRED_PRIOR_STATUS.includes(this.priorStatus)
            },
        },
        mounted: async function () {
            this.loading = false
            if (!this.isPreferredStatus) {
                logger.info(`LA status: ${this.priorStatus} is not a preferred status, skip confirmation`)
                return this.onContinueCurrent()
            }
            this.$logEvent('view_prompt_continue_prior_application')
        },
        methods: {
            onGoBack: async function () {
                await this.$router.replace(this.previousPath)
            },
        },
    }
</script>
